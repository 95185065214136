export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Блок 1',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Бизнес-аспекты решения. Архитектура решения. Разработка алгоритма (методологии) и физической реализации, включающей архитектуру, методы обмена данными и интеграцию баз данных с учетом защиты персональных данных.',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 2,
                            name: 'Качество дополнительных продаж: насколько предложенная клиенту услуга является релевантной. Пример: если человек приобретает автомобиль, то автокредит имеет для него потенциальную ценность. Однако трудно определить, заинтересует ли его предложение по отдыху в парк-отеле. В первом случае предложение будет уместным и качественным, в то время как во втором это скорее похоже на "холодные" продажи.',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 2,
                title: 'Блок 2',
                sections:
                    [
                        {
                            id: 3,
                            name: 'Функциональность и удобство использования: насколько прототип обеспечивает эффективное взаимодействие с клиентами и управление клиентской информацией.',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 4,
                            name: 'Интеграция с существующими системами: способность прототипа интегрироваться с уже используемыми IT-системами компаний.',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 5,
                            name: 'Потенциал масштабируемости: насколько легко и эффективно можно масштабировать предложенное бизнес-решение для всех компаний, входящих в число компаний-партнеров Экспобанка.',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]

            },
        ];
    return criteria;


}