<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>ЭКСПОХАК</h4>
              </div>
              <div class="card-body">
                <b>What’s the Hack? ЭКСПО!</b>
                <br><br>
                Этим летом с 1 по 10 августа прими участие в хакатоне от Экспобанк, реши задачу по созданию системы управления клиентскими базами для компаний-партнеров Экспобанка и получи возможность забрать вознаграждение из призового фонда в 400 000 рублей!
                <br><br>
                Хакатон пройдет в формате онлайн - 9 дней интенсивной работы над проектом, а 10-го августа участники защищают свои решения перед представителями из компаний-партнеров Экспобанка.
                <br><br>
                Главная цель – создать единый механизм управления клиентскими базами, позволяющий оптимизировать взаимодействие с клиентами и предлагать различные услуги из разных компаний, входящих в число компаний-партнеров Экспобанка.
                <br><br>
                Общий призовой фонд хакатона — 400 000 рублей, с наградами для первых трех победителей:
                <br><br>
                <ul>
                  <li>1 место — 200 000 рублей;</li>
                  <li>2 место — 125 000 рублей;</li>
                  <li>3 место — 75 000 рублей;</li>
                </ul>
              </div>
            </div>


            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Организатор - АО «Экспобанк»</h4>
              </div>
              <div class="card-body">
                АО «Экспобанк» — современный частный российский банк. 30 лет успешно работает на рынке в сегменте крупного бизнеса, МСБ, автокредитования и розницы.
                <br><br>
                Команда банка обладает большим опытом в реализации сделок по покупке и консолидации активов (M&A), финансирует и оказывает консалтинговые услуги клиентам по сопровождению сложноструктурированных сделок.
                <br><br>
                Экспобанк входит в топ-40 крупнейших банков России и занимает устойчивую конкурентную позицию на финансовом рынке. По данным Коммерсантъ за I полугодие 2023 года, банк входит в топ-10 по рентабельности и эффективности среди российских кредитных организаций.
                <br><br>
                Экспобанк обладает высокими рейтингами (ruA/Стабильный (Эксперт РА), A.ru/Стабильный (НКР)), отражающими финансовую устойчивость банка, высокую ликвидность и достаточность капитала.
                <br><br>
                Порядка 40 офисов банка расположены в крупнейших городах: в Москве, Санкт-Петербурге, Новосибирске, Кемерове, Курске, Воронеже, Орле, Брянске, Нижнем Новгороде, Омске, Белгороде, Екатеринбурге, Перми, Уфе, Красноярске, Сургуте, Владивостоке, Хабаровске, Южно-Сахалинске.
              </div>
            </div>


            <div class="card card-outline card-danger d-none">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>

              <div class="card-body">

                <div class="row">
                  <div class="col-xl-6 col-sm-12 text-center">
                  <img
                      src="https://world-code.ru/storage/images/logo/logo_partner_3222190275_3843287867.png"
                      class="logo m-3 img-fluid"
                  >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_2382592003_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_2687333192_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_1225890490_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>